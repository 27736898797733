import React, { Component } from "react"
import PropTypes from "prop-types"
import ClassNames from "classnames"
import * as SharedTypes from "constants/propTypes"
import Link from "components/Link"
import MobileMenu from "./MobileMenu"
import "./Header.scss"

const indexAnchorsOnly = links => links.filter(item => item.link.match(/^\/#/))

const renderNavigationLinks = links =>
  links.map(link => <Link {...link} key={link.link} />)

const isAboveViewport = element => {
  const bounding = element.getBoundingClientRect()
  return bounding.bottom <= 2
}

class Header extends Component {
  constructor() {
    super()
    this.state = { isSticky: false }

    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll() {
    const heroElement = document.getElementById("hero")
    if (!heroElement) {
      return
    }
    const shouldStick = isAboveViewport(heroElement)
    const { isSticky } = this.state

    if (isSticky !== shouldStick) {
      this.setState({ isSticky: shouldStick })
    }
  }

  render() {
    const { socialLinks, navigationLinks } = this.props
    const headerLinks = indexAnchorsOnly(navigationLinks)
    const { isSticky } = this.state
    const headerClasses = ClassNames("header", { "header--sticky": isSticky })

    return (
      <header className={headerClasses}>
        <nav className="header__nav">{renderNavigationLinks(headerLinks)}</nav>
        <MobileMenu
          navigationLinks={navigationLinks}
          socialLinks={socialLinks}
        />
      </header>
    )
  }
}

Header.propTypes = {
  navigationLinks: PropTypes.arrayOf(PropTypes.shape(SharedTypes.Link))
    .isRequired,
  socialLinks: PropTypes.arrayOf(PropTypes.shape(SharedTypes.ImageLink))
    .isRequired,
}

export default Header
