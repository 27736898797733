import React from "react"
import PropTypes from "prop-types"
import * as SharedTypes from "constants/propTypes"
import Link from "components/Link"
import ImageLink from "components/ImageLink"
import "./Footer.scss"

const renderSocialLinks = links =>
  links.map(link => <ImageLink {...link} key={link.link} />)

const renderNavigationLinks = links =>
  links.map(link => <Link {...link} key={link.link} />)

const Footer = ({ siteLogo, navigationLinks, socialLinks }) => (
  <footer className="footer">
    <img
      className="footer__logo"
      src={siteLogo.image.publicURL}
      alt={siteLogo.alt}
    />
    <div className="footer__links">
      <nav className="footer__links__nav">
        {renderNavigationLinks(navigationLinks, Link)}
      </nav>
      <div className="footer__links__social">
        {renderSocialLinks(socialLinks, ImageLink)}
      </div>
    </div>
  </footer>
)

Footer.propTypes = {
  siteLogo: PropTypes.shape(SharedTypes.SVG),
  navigationLinks: PropTypes.arrayOf(PropTypes.shape(SharedTypes.Link)),
  socialLinks: PropTypes.arrayOf(PropTypes.shape(SharedTypes.ImageLink)),
}

Footer.defaultProps = {
  siteLogo: undefined,
  navigationLinks: [],
  socialLinks: [],
}

export default Footer
