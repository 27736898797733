import React from "react"
import { Link as ScrollLink } from "react-scroll"
import { Link as GatsbyLink } from "gatsby"
import { Location } from "@reach/router"
import * as SharedTypes from "constants/propTypes"

/**
 * Some navigation links in app Layout are anchor links
 * When linking to an anchor on same page should scroll to location
 */
const Link = ({ link, label, onClick }) => (
  <Location>
    {({ location }) => {
      const preHashPath = link.split("#")[0]
      const shouldScrollLink = preHashPath === location.pathname

      return shouldScrollLink ? (
        <ScrollLink
          to={link.replace("/#", "")}
          href={link}
          smooth
          duration={500}
          onClick={onClick}
        >
          {label}
        </ScrollLink>
      ) : (
        <GatsbyLink to={link}>{label}</GatsbyLink>
      )
    }}
  </Location>
)

Link.propTypes = SharedTypes.Link

export default Link
