import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import SEO from "components/seo"
import { ModalProvider } from "components/Modal"
import Analytics from "components/Analytics"
import Header from "./Header"
import Footer from "./Footer"

import "styles/main.scss"
import "./Layout.scss"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            title
          }
        }
        scale19Json {
          siteLogo {
            image {
              publicURL
            }
            alt
          }
          socialLinks {
            link
            alt: label
            image {
              publicURL
            }
          }
          navigationLinks {
            link
            label
          }
        }
      }
    `}
    render={({ scale19Json: data, site }) => (
      <div className="layout">
        <ModalProvider>
          <SEO title={site.siteMetadata.title} keywords={[]} />
          <Analytics />
          <Header
            navigationLinks={data.navigationLinks}
            socialLinks={data.socialLinks}
          />
          <main>{children}</main>
          <Footer
            siteLogo={data.siteLogo}
            navigationLinks={data.navigationLinks}
            socialLinks={data.socialLinks}
          />
        </ModalProvider>
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
