import React from "react"
import PropTypes from "prop-types"
import { Modal, ModalToggle } from "components/Modal"
import Link from "components/Link"
import ImageLink from "components/ImageLink"
import * as SharedTypes from "constants/propTypes"
import "./MobileMenu.scss"

const renderSocialLinks = links =>
  links.map(link => <ImageLink {...link} key={link.link} />)

const renderNavigationLinks = (links, onClick) =>
  links.map(link => <Link {...link} key={link.link} onClick={onClick} />)

const MobileMenu = ({ navigationLinks, socialLinks }) => (
  <>
    <ModalToggle id="mobile-menu-modal">
      {openModal => (
        <button
          className="mobile-menu-toggle"
          onClick={openModal}
          type="button"
        />
      )}
    </ModalToggle>
    <Modal id="mobile-menu-modal">
      {closeModal => (
        <div className="mobile-menu">
          <button
            type="button"
            onClick={closeModal}
            className="mobile-menu__close"
          />
          <nav className="mobile-menu__nav">
            {renderNavigationLinks(navigationLinks, closeModal)}
          </nav>
          <div className="mobile-menu__social">
            {renderSocialLinks(socialLinks)}
          </div>
        </div>
      )}
    </Modal>
  </>
)

MobileMenu.propTypes = {
  navigationLinks: PropTypes.arrayOf(PropTypes.shape(SharedTypes.Link)),
  socialLinks: PropTypes.arrayOf(PropTypes.shape(SharedTypes.ImageLink)),
}

MobileMenu.defaultProps = {
  navigationLinks: [],
  socialLinks: [],
}

export default MobileMenu
