import PropTypes from "prop-types"

export const Link = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export const SVG = {
  image: PropTypes.shape({
    publicURL: PropTypes.string.isRequired,
  }).isRequired,
  alt: PropTypes.string.isRequired,
}

export const ImageLink = {
  ...SVG,
  link: PropTypes.string.isRequired,
}

export const CTA = {
  value: PropTypes.string.isRequired,
  action: PropTypes.string,
}

export const Ticket = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  nextPrices: PropTypes.arrayOf(PropTypes.string).isRequired,
  checkoutWidgetTigger: PropTypes.string.isRequired,
  CTA: PropTypes.string.isRequired,
}
