import React from "react"
import Helmet from "react-helmet"

const Analytics = () => (
  <Helmet>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=UA-61193052-4"
    />
    <script>
      {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments)}
        gtag('js', new Date());
        gtag('config', 'UA-61193052-4');`}
    </script>
  </Helmet>
)

export default Analytics
