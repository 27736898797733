import React from "react"
import * as SharedTypes from "constants/propTypes"
import "./ImageLink.scss"

const ImageLink = ({ link, image, alt }) => (
  <a className="image-link" href={link} key={link}>
    <img src={image.publicURL} alt={alt} />
  </a>
)

ImageLink.propTypes = SharedTypes.ImageLink

export default ImageLink
